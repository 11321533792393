import React from "react"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import * as brandBodyStyles from "./BrandBody.module.scss"

const BrandBody = ({}) => {
  return (
    <div className={brandBodyStyles.brandBody}>
      <div className={brandBodyStyles.about}>
        <div className={brandBodyStyles.info}>
          <h2>About Ranger</h2>
          <p>Built for rugged durability and extreme protection, Ranger offers boots available with features that cater to the individual needs of users in a variety of hazardous, wet, or cold-weather environments.</p>
          <p>For industrial workers, tough, hard-working treads and knurled protective overlays add durability to these tougher-than-tough boots.</p>
          <p>For outdoor enthusiasts, quality, yet value priced options that remain feature rich are a mainstay of the brand.</p>
        </div>
        <div className={brandBodyStyles.rightImage}>
          <StaticImage
            src="../../assets/images/index/brand-body/ranger/ranger-line-right.jpg"
            className={brandBodyStyles.halfImage}
            alt="Rocky factory 1933"
            imgStyle={{width: `100%`, height: `100%`}}
            style={{width: `100%`, height: `100%`}}
          />
        </div>
      </div>
    </div>
  )
}

export default BrandBody
