import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BrandHeader from "../../components/brand-headers/rangerBrandHeader"
import BrandBody from "../../components/brand-bodies/rangerBrandBody"

const RangerBootsPage = ({ pageContext, location }) => {
  const pageTitle = "Ranger"

  return (
    <>
      <Layout>
        <Seo title={pageTitle} />
        <BrandHeader />
        <BrandBody />
      </Layout>
      {/* description for search, hidden from page */}
      <p
        data-typesense-field="description"
        style={{ display: `none` }}
        aria-hidden="true"
      >
        Built for rugged durability and extreme protection, Ranger offers boots
        available with features that cater to the individual needs of users in a
        variety of hazardous, wet, or cold-weather environments.For industrial
        workers, tough, hard-working treads and knurled protective overlays add
        durability to these tougher-than-tough boots. For outdoor enthusiasts,
        quality, yet value priced options that remain feature rich are a
        mainstay of the brand.
      </p>
    </>
  )
}

export default RangerBootsPage

//head element
export const Head = () => (
  <>
    <title data-typesense-field="title">Ranger</title>
    <meta
      name="description"
      content="Built for rugged durability and extreme protection, Ranger offers boots available with features that cater to the individual needs of users in a variety of hazardous, wet, or cold-weather environments.For industrial workers, tough, hard-working treads and knurled protective overlays add durability to these tougher-than-tough boots.
      For outdoor enthusiasts, quality, yet value priced options that remain feature rich are a mainstay of the brand."
    />
  </>
)
